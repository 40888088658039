import React from 'react'
import socket from './socket'

export default class Like extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      like: 0
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <div style={{width:'100vw', 
        height:'100vh', 
      }}>
        <div style={{display:'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}>
          <span style={{fontSize: '12vw'}}>http://aki.bz</span>
          <div><img src="/akibzqr.png" style={{height: '30vh'}}/></div>
        </div>
      </div>
    )
  }
}

