import React from 'react'
import socket from './socket'

export default class Like extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      like: 0
    }
  }

  componentDidMount() {
    socket.on('broadcast:like', () => {
      this.setState({
        like: this.state.like + 1
      })
    })
    socket.on('broadcast:like:clear', () => {
      this.setState({
        like: 0
      })
    })
  }

  render() {
    const {like} = this.state
    return (
      <div style={{width:'100vw', 
        height:'100vh', 
      }}>
        <div style={{display:'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}>
          <span style={{fontSize: '30vw'}}>{like}<span style={{fontSize: '5vw'}}>いいね</span></span>
        </div>
      </div>
    )
  }
}

