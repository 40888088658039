import React, {useState, useEffect} from 'react'
import socket from './socket'

export default function Like3() {
  const [colors, setColors] = useState([])
  const [like, setLike] = useState(0)
  const getColor = () => {
    const vals = ["red", "green", "blue"]
    const random = Math.floor(Math.random() * vals.length);
    return vals[random]
  }

  useEffect(() => {
    const _colors = []
    for (var i = 0; i < 25; i++) {
      _colors.push(getColor())
    }
    setColors(_colors)
    
    socket.on('broadcast:like', () => {
      setColors(colors => [getColor(), ...colors.slice(0, 24)])
      setLike(like + 1)
    })
    return () => {
      socket.off('broadcast:like')
    }
  }, [])

  const sendColor = (color) => {
    setColors([color, ...colors.slice(0, 24)])
  }

  return (
    <div style={{width:'100vw', 
      height:'100vh', 
      display:'flex',
      flexWrap: 'wrap',
    }}>
      {colors.map((color, idx) => (
        <div key={idx} onClick={() => sendColor(color)} style={{width: '20vw', height: '20vh', background: color, outlineOffset: '-2px', outline: 'solid 2px #fff'}}>
          &nbsp;
        </div>
      ))}
    </div>
  )
}
