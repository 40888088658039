import React from 'react'
import socket from './socket'

export default class Screen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      images: {}
    }
  }

  componentDidMount() {
    socket.on('broadcast:image', (image) => {
      console.log(image.id)
      this.setState({
        images: {
          ...this.state.images,
          [image.id]: image
        }
      })
    })
  }

  render() {
    const {images} = this.state
    let imgs = []
    for (var key in images) {
      const image = images[key];
      imgs.push(image)
    }
    return (
      <div>
        {imgs.map((image, idx) => (
          <div key={idx} style={{width: '24vw', height:'20vw', float:'left'}}>
            <img src={image.image} style={{width: '24vw', height:'18vw', border: 'solid 1px gray'}} />
            <div style={{textAlign:'center'}}>{image.name}</div>
          </div>
        ))}
      </div>
    )
  }
}
