import React from 'react'
import socket from './socket'

export default class Console extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    socket.on('broadcast:like', () => {
      this.setState({
        like: this.state.like + 1
      })
    })

  }

  handleLikeClear = () => {
    socket.emit('like:clear');
  }

  render() {
    const {like} = this.state
    return (
      <div>
        <button onClick={this.handleLikeClear}>Like clear</button>
      </div>
    )
  }
}

