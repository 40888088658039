import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Screen from './Screen';
import Like from './Like';
import Like2 from './Like2';
import Like3 from './Like3';
import Console from './Console';
import Display from './Display';
import * as serviceWorker from './serviceWorker';
import {
    HashRouter,
    BrowserRouter,
    Route,
    Switch,
    Link
} from 'react-router-dom';

const Router = () => (
    <Switch>
      <Route exact path='/' component={App} />
      <Route path='/screen' component={Screen} />
      <Route path='/like' component={Like} />
      <Route path='/like2' component={Like2} />
      <Route path='/like3' component={Like3} />
      <Route path='/console' component={Console} />
      <Route path='/display' component={Display} />
    </Switch>
)

ReactDOM.render(<HashRouter><Router /></HashRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
