import React from 'react'
import socket from './socket'

  const likeAnimate = function() {
    var r = Math.random() * 360;
    var heart = document.createElement('heart');
    const right = Math.random() * document.body.clientWidth || 100;
    const bottom = 90 + Math.random() * 80;
    heart.style.right = right + 'px';
    heart.style.bottom = bottom + 'px';
    const color = Math.random()
    if (color < 0.2) {
        heart.classList.add("blue");
    } else if (color < 0.4) {
        heart.classList.add("green");
    }

    const right1 = right + Math.random() * 10
    const right2 = right - Math.random() * 10
    const speed = 80 + 40 * Math.random()

    heart.animate([
       {right: right1 + 'px', bottom: (bottom + speed * 0) + 'px'},
       {right: right2 + 'px', bottom: (bottom + speed * 1) + 'px'},
       {right: right1 + 'px', bottom: (bottom + speed * 2) + 'px'},
       {right: right2 + 'px', bottom: (bottom + speed * 3) + 'px', opacity:0.6},
       {right: right1 + 'px', bottom: (bottom + speed * 4) + 'px', opacity: 0.4},
       {right: right2 + 'px', bottom: (bottom + speed * 5) +  'px', opacity: 0},
    ], {duration: 2500, fill: 'both'}).addEventListener('finish', function() {
      document.body.removeChild(heart);
    });
    document.body.appendChild(heart);
  }


export default class Like2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      like: 0
    }
  }

  componentDidMount() {
    socket.on('broadcast:like', () => {
      likeAnimate()
      likeAnimate()
      likeAnimate()
      likeAnimate()
      likeAnimate()
      this.setState({
        like: this.state.like + 1
      })
    })
    socket.on('broadcast:like:clear', () => {
      this.setState({
        like: 0
      })
    })

  }

  render() {
    const {like} = this.state
    const handleLik =  () => {
      likeAnimate()
      likeAnimate()
      likeAnimate()
      likeAnimate()
      likeAnimate()
    }

    return (
      <div style={{width:'100vw', 
        height:'100vh', 
      }}>
        <div style={{display:'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}>
          <span style={{fontSize: '30vw'}} onClick={handleLik}>{like}<span style={{fontSize: '5vw'}}>いいね</span></span>
        </div>
      </div>
    )
  }
}
