import io from 'socket.io-client'

let socket;

if (process.env.NODE_ENV === 'production') {
  socket = io.connect("wss://aki.bz")
} else {
  socket = io.connect("http://localhost:3001")
}

export default socket
