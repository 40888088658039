import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import socket from "./socket";
import Button from "@material-ui/core/Button";
import FavoriteIcon from "@material-ui/icons/Favorite";

class App extends Component {
  constructor() {
    super();
    this.state = {
      drawing: false,
      name: "",
    };
  }

  getContext = () => {
    return this.refs.canvas.getContext("2d");
  };

  startDrawing = (x, y) => {
    this.setState({ drawing: true });
    const ctx = this.getContext();
    ctx.moveTo(x, y);
  };

  startDrawingSP = (e) => {
    var x = e.touches[0].pageX - e.touches[0].target.offsetLeft;
    var y = e.touches[0].pageY - e.touches[0].target.offsetTop;

    this.setState({ drawing: true });
    const ctx = this.getContext();
    ctx.moveTo(x, y);
  };

  draw = (x, y) => {
    if (!this.state.drawing) {
      return;
    }
    const ctx = this.getContext();
    ctx.lineTo(x, y);
    ctx.lineWidth = 3;
    ctx.stroke();
  };

  drawSP = (e) => {
    var x = e.touches[0].pageX - e.touches[0].target.offsetLeft;
    var y = e.touches[0].pageY - e.touches[0].target.offsetTop;

    const ctx = this.getContext();
    ctx.lineTo(x, y);
    ctx.lineWidth = 3;
    ctx.stroke();
  };

  endDrawing() {
    this.setState({ drawing: false });
  }

  endDrawingSP() {
    this.setState({ drawing: false });
  }

  componentDidMount = () => {
    socket.on("image:loaded", (params) => {
      console.log(params);
    });
  };

  showLike = () => {
    var r = Math.random() * 360;
    var heart = document.createElement("heart");
    const right = (window.innerWidth / 2 - 40) + Math.random() * 80;
    const bottom = (window.innerHeight * 0.05 + window.innerWidth * 0.4 - 20) + Math.random() * 80;
    heart.style.right = right + "px";
    heart.style.bottom = bottom + "px";
    const color = Math.random();
    if (color < 0.2) {
      heart.classList.add("blue");
    } else if (color < 0.4) {
      heart.classList.add("green");
    }

    const right1 = right + Math.random() * 10;
    const right2 = right - Math.random() * 10;
    const speed = 80 + 40 * Math.random();

    heart
      .animate(
        [
          { right: right1 + "px", bottom: bottom + speed * 0 + "px" },
          { right: right2 + "px", bottom: bottom + speed * 1 + "px" },
          { right: right1 + "px", bottom: bottom + speed * 2 + "px" },
          {
            right: right2 + "px",
            bottom: bottom + speed * 3 + "px",
            opacity: 0.6,
          },
          {
            right: right1 + "px",
            bottom: bottom + speed * 4 + "px",
            opacity: 0.4,
          },
          {
            right: right2 + "px",
            bottom: bottom + speed * 5 + "px",
            opacity: 0,
          },
        ],
        { duration: 1000, fill: "both" }
      )
      .addEventListener("finish", function () {
        document.body.removeChild(heart);
      });
    document.body.appendChild(heart);
  };

  handleClick = () => {
    socket.emit("like");
    for (var i = 0; i <= 7; i++) {
      this.showLike();
    }
  };

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handleSubmit = () => {
    const cx = this.getContext();
    const png = this.refs.canvas.toDataURL();
    socket.emit("image", {
      name: this.state.name,
      image: png,
    });
  };

  clearCanvas = () => {
    const ctx = this.getContext();
    ctx.clearRect(0, 0, this.refs.canvas.width, this.refs.canvas.height);
    ctx.beginPath();
  };

  render() {
    const width = 360;
    const height = 270;

    return (
      <div>
        {/*
      <canvas
        ref="canvas"
        width={width}
        height={height}
        onMouseDown={e => this.startDrawing(e.nativeEvent.offsetX, e.nativeEvent.offsetY)}
        onTouchStart={e => this.startDrawingSP(e)}
        onTouchEnd={e => this.endDrawingSP(e)}
        onTouchMove={e => this.drawSP(e)}
        onMouseUp={() => this.endDrawing()}
        onMouseLeave={() => this.endDrawing()}
        onMouseMove={e => this.draw(e.nativeEvent.offsetX, e.nativeEvent.offsetY)}
        style={{border: '1px solid gray', background: '#fff'}}
      />
      <div>
        ▲感想エリア
      </div>
      <div>
        <input type="text" onChange={this.handleNameChange} placeholder="お名前" style={{width: '80vw', fontSize: '6vw'}}/>
      </div>
      */}
        <div
          style={{
            position: "absolute",
            bottom: "5vh",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            style={{
              width: "40vw",
              height: "40vw",
              fontSize: "5vw",
              borderRadius: "50%",
              backgroundColor: "pink",
            }}
            onClick={this.handleClick}
          >
            <FavoriteIcon style={{ fontSize: "20vw", color: "#fff" }} />
          </Button>
        </div>
      </div>
    );
  }
}

export default App;
